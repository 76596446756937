// D'ORSOGNA

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "D’Orsogna Limited",
      ampFund: true,
    },
    superFund: `
      <div class="section-title mt-5">
        <h2>
          D’Orsogna Super Fund
        </h2>
      </div>
      <div class="row content mb-5">
        <div class="col-lg-6">
          <p>
            The <strong>D’Orsogna Super Fund</strong> is a large fund where all members’ savings are pooled together so that you pay less fees, which means you have more super savings invested, and more money in your pocket for the future.
          </p>
          <p class="mt-5">
            The D’Orsogna Fund offers you access to <strong>large fund discounts, insurances at discounted rates</strong>, plus a wide range of investment choice.
          </p>          
        </div>
        <div class="col-lg-6">
          <p>
            <strong>Gallagher</strong> is here to provide you with all the assistance you need to do with your super, and to show you how to take control of your long term savings so that you and your family are looked after.
          </p>
          <div class="col-lg-6 mt-5 px-0 text-center text-lg-center">
            <a
              href="#/#docs"
              class="btn-learn-more"
            >
              Learn more about the super fund
            </a>
          </div>
        </div>
      </div>      
      `,
    pdfs: [
      {
        title: "Building your Super Fact Sheet",
        description:
          "View all the different ways you can build your superannuation savings.",
        type: "form",
        url: "Gallagher-Building_Your_Super_Factsheet-2024-2025.pdf",
      },
      {
        title: "Join the D’Orsogna fund",
        description:
          "A summary of benefits available to you, including your super choice form.",
        type: "form",
        url: "D'Orsogna-New_Employee_Pack-October_2023.pdf",
      },
      {
        title: "Overview for Senior Employees",
        description: "",
        type: "info",
        url: "Overview_for_Senior_Employees-April2024.pdf",
      },
      {
        title: "Overview for Office Employees",
        description: "",
        type: "info",
        url: "Overview_for_Office_Employees-April_2024.pdf",
      },
      {
        title: "Overview for General Employees",
        description: "",
        type: "info",
        url: "Overview_for_General_Employees-April_2024.pdf",
      },
      {
        title: "ATO Rollover Form",
        description:
          "Do you need to rollover your previous super fund to your new super fund?",
        type: "form",
        externalUrl:
          "https://www.ato.gov.au/Forms/Request-for-rollover-of-whole-balance-of-super-benefits-between-funds---Instructions/#Howtogettheform",
      },
      {
        title: "Signature Super Investment Options",
        description: "Do you want to change your investment choice?",
        type: "info",
        url: "DOrsogna_AMP_SS Investment_Guide_30092023.pdf",
      },
      {
        title: "Beneficiary Nomination",
        description:
          "Do you need to nominate a beneficiary on your super fund?",
        type: "form",
        url: "DOrsogna_AMP_Beneficiary_Form_11102023.pdf",
      },
      {
        title: "AMP PDS & Fact Sheets",
        description: "Additional information on your super fund.",
        type: "form",
        externalUrl:
          "https://www.amp.com.au/employer/manage-your-plan/pds-and-fact-sheets",
      },
      {
        title: "Investment Selection",
        description: "Do you want to change your investment choice?",
        type: "form",
        url: "DOrsogna_AMP_Investment_option_form_03032023.pdf",
      },
      {
        title: "Member Guide Fact Sheet",
        description: "Additional information on your super fund.",
        type: "form",
        url: "AMP_SS_Member_Guide_01042024.pdf",
      },
      {
        title: "Transfer existing insurance",
        description: "",
        type: "form",
        url: "MET_AMP-Insurance-Transfer-Form-20181002-002.pdf",
      },
    ],
    optInLink: "https://secure.amp.com.au/ddc/public/ui/pmif/",
    teamMembers: [
      {
        teamMemberPic: "sharron.jpg",
        name: "Sharron Wharton-Street",
        phoneHref: "0862508493",
        phone: "08 6250 8493",
        email: "Sharron_WhartonStreet@ajg.com.au",
        social: "https://www.linkedin.com/in/sharron-wharton-street-801026161/",
      },
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "natasha.png",
        name: "Natasha Van",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "super-service@ajg.com.au",
      },
    ],
    footerClientName: "D’Orsogna Limited",
  },
  superscore: false,

  analytics: {
    gacode: "",
    gaprod: "",
  },
};
